<template>
  <router-view ref="pageChild" @rollInit="rollInitEvent" @rollEnd="rollEndEvent" @rollTop="rollTopEvent" @rollPage="rollPageEvent" v-model="value"/>
</template>

<script>
export default {
  name: 'page-child',
  props: {
    value: Boolean,
  },
  methods: {
    rollInitEvent() {
      this.$emit('rollInit');
    },
    rollEndEvent(page) {
      this.$emit('rollEnd', page);
    },
    rollTopEvent() {
      this.$emit('rollTop');
    },
    rollPageEvent(page) {
      this.$emit('rollPage', page);
    },
  },
};
</script>
